import React from 'react'
import { useEffect } from 'react'
import { doGET } from '../../util/httpUtil'
import { ENDPOINTS } from '../../services/api/constants'
import { useState } from 'react'

//TODO optimize this component
const ConsultantDetail = ({ _id }) => {
    const [data, setData] = useState(null);

    const fetchDetail = async () => {
        try {
            const response = await doGET(ENDPOINTS.detail('consultant', _id));
            setData(response.data.data);
        } catch (error) {

        }
    }

    useEffect(() => {
        if (_id) {
            fetchDetail()
        }
    }, [_id])

    return (
        <div>
            {data ? (
                data?.displayName
            ) : ""}
        </div>
    )
}

export default ConsultantDetail