import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/WISHTOK_Logo_long.jpg';
import { UserContext } from "../../store/context/userContext";
import { MdOutlineDashboard, MdCurrencyExchange, MdMenu, MdMarkChatRead } from "react-icons/md";
import { RiUserAddLine, RiUser2Line, RiUserSettingsLine, RiUserStarLine } from "react-icons/ri";
import { BiLandscape, BiSolidOffer, BiSolidCategory } from "react-icons/bi";
import { TfiBell, TfiHeadphoneAlt } from "react-icons/tfi";
import { IoNotificationsOutline, IoSettingsSharp } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdRecommend } from "react-icons/md";
import { FiDollarSign } from "react-icons/fi";
import { SiGotomeeting } from "react-icons/si";
import { FaQuestionCircle } from "react-icons/fa";

import "./layout.scss";

const Sidebar = ({ openMenu = () => { } }) => {
  const { setRoles } = useContext(UserContext);
  // const storedActiveLink = localStorage.getItem('activeLink');
  // const [activeLink, setActiveLink] = useState(storedActiveLink || 'Dashboard');
  const [activeLink, setActiveLink] = useState(window.location.pathname.split('/')[1]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const storedIsSidebarOpen = true;
  // const storedIsSidebarOpen = JSON.parse(localStorage.getItem('isSidebarOpen')) || false;
  const [isSidebarOpen, setIsSidebarOpen] = useState(storedIsSidebarOpen);

  if (activeLink === "client") { setActiveLink("customer") };

  const toggleSidebar = () => {
    const newIsSidebarOpen = !storedIsSidebarOpen;
    localStorage.setItem('isSidebarOpen', JSON.stringify(newIsSidebarOpen));
    openMenu({ isMenu: newIsSidebarOpen, title: activeLink.charAt(0).toUpperCase() + activeLink.slice(1) })
    setIsSidebarOpen(newIsSidebarOpen);
  };

  useEffect(() => {
    // localStorage.setItem('activeLink', activeLink);
    openMenu({ isMenu: isSidebarOpen, title: activeLink.charAt(0).toUpperCase() + activeLink.slice(1) })
    if (shouldRefresh) {
      window.location.reload(true);
      setShouldRefresh(false);
    }
  }, [activeLink, shouldRefresh, window.location.pathname]);


  const handleClick = (link) => {
    if (link === activeLink) {
      setShouldRefresh(true);
    } else {
      setActiveLink(link);
    }
  };

  const hasAccess = (val) => {
    const role = JSON.parse(localStorage.getItem("roles"))
    return role?.includes(val);

  }
  return (
    <React.Fragment>
      {/* //component for closed Sidebar */}
      {!isSidebarOpen ? <div className="sidebar-main-closed">
        <div className="ms-4">
          <MdMenu size={18} onClick={toggleSidebar} style={{ cursor: 'pointer' }} />
        </div>
        <div className=" text-center m-2" style={{ maxWidth: "70px", paddingLeft: "8px" }}>
          <Link
            to="/dashboard"
            style={{
              marginTop: "8px",
              color: "black",
              backgroundColor: activeLink === 'dashboard' ? 'rgba(255, 182, 25, 0.72)' : '',
              padding: "10px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleClick('dashboard')}
          >
            <MdOutlineDashboard size={18} />
          </Link>

          {hasAccess("Customer") ? <Link to="/customer" style={{
            marginTop: "10px", color: "black", backgroundColor: activeLink === 'customer' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
            alignItems: "center",
          }} onClick={() => handleClick('customer')}>
            <RiUserAddLine size={18} />

          </Link> : null}

          {hasAccess("Consultant") ?
            <Link to="/consultant" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'consultant' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('consultant')}>
              <RiUser2Line size={18} />


            </Link> : null}

          {hasAccess("Finance") ?
            <Link to="/finance" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'finance' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('finance')}>
              <FaMoneyBillTrendUp size={18} />
            </Link> : null}

          {hasAccess("Finance") ?
            <Link to="/my-transaction" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'my-transaction' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('my-transaction')}>
              <FaMoneyBillTrendUp size={18} />
            </Link> : null}

          {hasAccess("Admin") ?
            <Link to="/offer" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'offer' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('offer')}>
              <BiSolidOffer size={18} />
            </Link> : null}


          {hasAccess("Admin") ?
            <Link to="/currencyRate" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'currencyRate' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('currencyRate')}>
              <MdCurrencyExchange size={18} />
            </Link> : null}

          {hasAccess("Admin") ?
            <Link to="/configuration" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'configuration' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center"
            }} onClick={() => handleClick('configuration')}>
              <IoSettingsSharp size={18} />

            </Link> : null}

          {hasAccess("Reviews") ?
            <Link to="/review" style={{
              marginTop: "10px", color: "black", backgroundColor: activeLink === 'review' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
              alignItems: "center",
            }} onClick={() => handleClick('review')}>
              <RiUserStarLine size={18} />
            </Link> : null}

          {hasAccess("Admin") ? <Link to="/supervisor" style={{
            marginTop: "10px", color: "black", backgroundColor: activeLink === 'supervisor' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
            alignItems: "center"
          }} onClick={() => handleClick('supervisor')}>
            <RiUserSettingsLine size={18} />
          </Link> : null}

          {hasAccess("Support") ? <Link to="/support" style={{
            marginTop: "10px", color: "black", backgroundColor: activeLink === 'support' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
            alignItems: "center",
          }} onClick={() => handleClick('support')}>
            <TfiHeadphoneAlt size={18} />
          </Link> : null}


          {hasAccess("Admin") ? <Link to="/banner" style={{
            marginTop: "10px", color: "black", backgroundColor: activeLink === 'banner' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
            alignItems: "center",
          }} onClick={() => handleClick('banner')}>
            <BiLandscape size={18} />
          </Link> : null}

        </div>
      </div> : null}


      {/* //component for open Sidebar */}
      {isSidebarOpen ? <div className={`sidebar-main`} style={{}}>
        <div className="container sidebar-main-content d-flex h-100 flex-column justify-content-between">
          <section style={{ fontSize: "18px", color: "black" }}>
            <div className="text-center m-2" style={{ position: "relative", paddingLeft: "8px" }}>
              {/* <div style={{ position: "absolute", top: 0, right: -10, }} className="sidebar-main-content me-3" onClick={toggleSidebar}>
                <MdMenu size={18} onClick={toggleSidebar} style={{ cursor: 'pointer' }} />
              </div> */}

              <Link to="/dashboard" onClick={() => handleClick("dashboard")}>
                <img src={logo} alt="" height="50" width="150" style={{ border: "none", marginRight: '60px', marginTop: '-10px' }} />
              </Link>
              <div>
                <Link
                  to="/dashboard"
                  style={{
                    marginTop: "8px",
                    color: "black",
                    backgroundColor: activeLink === 'dashboard' ? 'rgba(255, 182, 25, 0.72)' : '',
                    padding: "10px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="menu-label"
                  onClick={() => handleClick('dashboard')}
                >
                  <MdOutlineDashboard size={18} style={{
                    marginRight: "7px"
                  }} />
                  Dashboard
                </Link>

                {hasAccess("Customer") ? <Link to="/customer" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'customer' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center",
                }} className="menu-label" onClick={() => handleClick('customer')}>
                  <RiUserAddLine size={18} style={{
                    marginRight: "7px"
                  }} />
                  Customers
                </Link> : null}




                {hasAccess("Consultant") ?
                  <Link to="/consultant" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'consultant' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('consultant')}>
                    <RiUser2Line size={18} style={{
                      marginRight: "7px"
                    }} />

                    Consultants</Link> : null}

                {hasAccess("Finance") ?
                  <Link to="/finance" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'finance' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('finance')}>
                    <FaMoneyBillTrendUp size={18} style={{
                      marginRight: "7px"
                    }} />
                    Finance</Link> : null}

                {hasAccess("Finance") ?
                  <Link to="/my-transaction" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'my-transaction' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('my-transaction')}>
                    <FaMoneyBillTrendUp size={18} style={{
                      marginRight: "7px"
                    }} />
                    My Transactions</Link> : null}

                {hasAccess("Finance") ?
                  <Link to="/payout" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'payout' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('payout')}>
                    <FiDollarSign size={18} style={{
                      marginRight: "7px"
                    }} />
                    Payouts</Link> : null}

                {hasAccess("Admin") ?
                  <Link to="/offer" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'offer' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('offer')}>
                    <BiSolidOffer size={18} style={{
                      marginRight: "7px"
                    }} />
                    Offers</Link> : null}

                    {hasAccess("Admin") ?
                  <Link to="/webinar" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'webinar' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('webinar')}>
                    <SiGotomeeting size={18} style={{
                      marginRight: "7px"
                    }} />
                    Webinar</Link> : null}


                {hasAccess("Admin") ?
                  <Link to="/currencyRate" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'currencyRate' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('currencyRate')}>
                    <MdCurrencyExchange size={18} style={{
                      marginRight: "7px"
                    }} />

                    Currency Rate</Link> : null}
                {hasAccess("Reviews") ?
                  <Link to="/review" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'review' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center",
                  }} className="menu-label" onClick={() => handleClick('review')}>
                    <RiUserStarLine size={18} style={{
                      marginRight: "7px"
                    }} />
                    Reviews</Link> : null}

                {hasAccess("Admin") ?
                <Link to="/notification" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'Notification' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center",
                }} onClick={() => handleClick('Notification')}>
                  <TfiBell size={18} style={{
                    marginRight: "7px"
                  }} />
                  Notification</Link> : null}

                {hasAccess("Admin") ? <Link to="/supervisor" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'supervisor' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('supervisor')}>
                  <RiUserSettingsLine size={18} style={{
                    marginRight: "7px"
                  }} />
                  Users</Link> : null}

                {hasAccess("Support") ? <Link to="/support" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'support' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center",
                }} className="menu-label" onClick={() => handleClick('support')}>
                  <TfiHeadphoneAlt size={18} style={{
                    marginRight: "7px"
                  }} />
                  Support</Link> : null}


                {hasAccess("Admin") ? <Link to="/banner" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'Banner' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center",
                }} className="menu-label" onClick={() => handleClick('banner')}>
                  <BiLandscape size={18} style={{
                    marginRight: "7px"
                  }} />
                  Banner</Link> : null}
                  {hasAccess("Admin") ?
                  <Link to="/configuration" style={{
                    marginTop: "10px", color: "black", backgroundColor: activeLink === 'configuration' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                    alignItems: "center"
                  }} className="menu-label" onClick={() => handleClick('configuration')}>
                    <IoSettingsSharp size={18} style={{
                      marginRight: "7px"
                    }} />
                    Configuration
                  </Link> : null}

                <Link to="/notification" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'notification' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('notification')}>
                  <IoNotificationsOutline size={18} style={{
                    marginRight: "7px"
                  }} />
                  Notification
                </Link>

                <Link to="/recommendation" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'recommendation' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('recommendation')}>
                  <MdRecommend size={18} style={{
                    marginRight: "7px"
                  }} />
                  Recommendation
                </Link>

                <Link to="/faqs" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'faqs' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('faqs')}>
                  <FaQuestionCircle size={18} style={{
                    marginRight: "7px"
                  }} />
                  FAQs
                </Link>

                <Link to="/category" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'category' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('category')}>
                  <BiSolidCategory size={18} style={{
                    marginRight: "7px"
                  }} />
                  Categories
                </Link>
{/* 
                <Link to="/free-chat" style={{
                  marginTop: "10px", color: "black", backgroundColor: activeLink === 'free-chat' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px", display: "flex",
                  alignItems: "center"
                }} className="menu-label" onClick={() => handleClick('free-chat')}>
                  <MdMarkChatRead size={18} style={{
                    marginRight: "7px"
                  }} />
                  Free Chats
                </Link> */}
                
              </div>
            </div>

          </section>
        </div>
      </div > : null}
    </React.Fragment >
  );
};

export default observer(Sidebar);
