import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ENDPOINTS } from './ImageConstant';

import { Buffer } from 'buffer';
import { ThreeBounce } from 'better-react-spinkit';
import imageplaceholder from '../../assets/images/imagplaceholder.png'
import imageplaceholderDefault from '../../assets/images/placeholder_default.jpg'

const ImageComponent = ({ source, objectFit = 'cover', style = {}, width = 100, height = 200, className, type = 'user' }) => {
    const [imageUri, setImageUri] = useState(null);
    const [loading, setLoading] = useState(false);

    const getPlaceholderImageSource = () => {
        return type === "user" ? imageplaceholder : imageplaceholderDefault;
    }

    const fetchImage = async () => {
        setLoading(true)
        try {
            if (!source) {
                setImageUri(getPlaceholderImageSource())
            } else {
                const response = await axios.get(ENDPOINTS.staticFileUrl(source), {
                    responseType: 'arraybuffer',
                    headers: {
                        platform: 'browser'
                    }
                });

                let dataUri;

                if (response?.data?.byteLength === 0) {
                    dataUri = getPlaceholderImageSource();
                } else {
                    const contentType = response.headers['content-type'];
                    const imageData = Buffer.from(response.data, 'binary').toString('base64');
                    dataUri = `data:${contentType};base64,${imageData}`;
                }


                setImageUri(dataUri);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching image:', error);
            setImageUri(getPlaceholderImageSource())
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImage();
    }, [source]);

    return (
        <>
            {loading ? <div className={`d-flex align-items-center justify-content-center ${className}`} style={{ width: width, height: height, ...style }}>
                <ThreeBounce size={8} color="#9F73AB" />
            </div> :
                (imageUri && <img
                    // alt="Profile"
                    style={{ width: width, objectFit: 'contain', height: height, ...style }}
                    src={imageUri}
                />)}
        </>
    );
};

export default ImageComponent;