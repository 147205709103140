import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./WebinarConstant";
import { doGET, doPATCH, doPOST, doPUT } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';
  usageCount = {};
  sessionRecord=[];
  sessionTotalRecords=0;

  constructor() {
    makeAutoObservable(this);
  }

  getOfferUsage = async (records = []) => {
    const offers = records.map?.(e => e?.name)
    const response = await doPOST(ENDPOINTS.getTransactions, {
      filter: `status=2&userType=2&offerCode=in[${offers.join(',')}]&rows=-1`
    })
    const offerCount = response.data?.data?.rows.reduce((acc, item) => {
      const offerCode = item.offerCode;
      if (offerCode) {
        acc[offerCode] = (acc[offerCode] || 0) + 1;
      }
      return acc;
    }, {});
    this.usageCount = offerCount
  }

  fetch = async (filter) => {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filter));
    if (response.status === 200) {
      this.records = response.data.data.rows;
      this.totalRecords = response.data.data.records;
    }
    return this.records;
  };

  fetchSessions = async (filter, id) => {
    const response1 = await doGET(
      ENDPOINTS.sessionGrid(this.page, this.rows, filter, id));
      console.log(response1,"--------")
    if (response1.status === 200) {
      this.sessionRecord = response1.data.data;
      this.sessionTotalRecords = this.sessionRecord?.length ;
    }
    return this.sessionRecord;
  };

  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.edit, data);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };
  // delete = async (id) => {
  //   const response = await doGET(ENDPOINTS.delete(id));
  //   if (response.status === 200) {
  //     this.fetch();
  //   }
  // };
  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };
}

const OfferService = new Service();
export default OfferService;
