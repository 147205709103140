import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import {
  DateSelect,
  InputField,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import WebinarService from "./WebinarService";
import ImageComponent from "../../components/Image/ImageComponent";
import Checkbox from "../Consultant/Checkbox";
import DynamicInputField from "../../components/InputField/DynamicInputField";
import WebinarSession from "../../components/WebinarSession/WebinarSession";

import { toJS } from "mobx";
import { doDELETE, doPOST } from "../../util/httpUtil";
import { ThreeBounce } from "better-react-spinkit";
import { ENDPOINTS } from "../../common/utils/Constants";


const EditWebinar = (props) => {
  const [data, setData] = useState();
  const navigate = useNavigate()
  const [ disabled, setdisabled]= useState(false);
  const [sessions, setSessions] = useState(WebinarService?.sessionRecord);
  const { id } = useParams();
  const [loading, setloading]= useState(false);
  const [showErr, setShowErr]= useState(false);
  const { showError, showAlert, showMessage } =
    useContext(DialogContext);

    const fetchSessions = async ()=>{
      if(id){
        await WebinarService.fetchSessions(null,id);
      }
    }

  const getDetails = async () => {
    try {
      let response = {};
      if (id){ 
        response = await WebinarService.get(id);

        await fetchSessions();
      }

      setData(response?.data);
    } catch (error) {
      console.log(error)
     }
  };


  useEffect(() => {
    getDetails();
  }, [id]);

    const removeWebinar =  async (id) => {
      try {
        
  
        let response= await doDELETE(ENDPOINTS.delete("webinar", id));
        if(response?.status==200){
          showMessage("success","Webinar Deleted Successfully")
          showAlert({
            title:" Webinar has been deleted Successfully"
          });
          navigate('/webinar');
        }
      } catch (error) {
        console.error("Error Deleting session:", error);
        showError(error);
      }
    };


  // useEffect(() => {
  //   fetchSessions();
  //   setSessions(WebinarService.sessionRecord)
  // }, [WebinarService.sessionRecord?.length]);


  const submitForm = async () => {
    if(loading) return;
    try {
      console.log(data?.date,"[[[[[[")
      if(data?.title?.length==0 || isNaN(data?.date) ||  data?.date==null ){
        setShowErr(true);
        showError("Please Fill All Required Details")
        return;
      }
        setShowErr(false);

      let response;
      setloading(true);
      if (id) {
        response = await WebinarService.edit(data, id);
      } else {
        response = await WebinarService.save(data);
        navigate(`/webinar/edit/${response?.data?._id}`)
      }
      console.log(response?.data);
      setdisabled(false);
      getDetails();
      showAlert({
        title: id
          ? " Webinar has been Updated Successfully"
          : "Webinar has been Created Successfully and you can now Create sessions",
      });
    } catch (err) {
      showError(err?.message);
    }
    finally{
      setloading(false);
    }
  };

  const handleChange = (updatedValues) => {
    setData({ ...data, guideLines: updatedValues });
  };

  return (
<React.Fragment>
  <Card
    className="mt-3"
    style={{
      width: "auto",
      height: "calc(100vh - 100px)",
      overflowY: "scroll",
      marginRight: 20,
      marginLeft: 20,
    }}
  >
    <CardBody
      className="py-3 px-5"
      style={{ top: "10%", position: "relative", width: "95%" }}
    >
      <Row className="mt-3">
        {/* Title and Description in the same row */}
        <Col className="col-sm-6">
          <Label>Title</Label>
          <InputField
            className="col-sm-12"
            required={true}
            placeholder="Title"
            value={data?.title}
            onChange={(v) => setData({ ...data, title: v })}
            maxlength={3}
            showErr={showErr}
            error={(data?.title?.length==0 ||  data?.title==null) ? "Please Fill Title":null}
          />
        </Col>
        <Col className="col-sm-6">
          <Label>Description</Label>
              <Row className="mx-2">
                <textarea
                  className="col-sm-12"
                  placeholder="Enter Description"
                  value={data?.description}
                  onChange={(v) => setData({ ...data, description: v?.target?.value })}
                  rows={5}
                  cols={160}
                  style={{ border: "1px solid #ced4da", outline: "none" }}
                />
              </Row>
          {/* <InputField
            className="col-sm-12"
            required={true}
            placeholder="Description"
            value={data?.description}
            onChange={(v) => setData({ ...data, description: v })}
            maxlength={3}
            showErr={showErr}
            error={(data?.description?.length==0 || data?.description==null) ? "Please Fill Description":null}
          /> */}
        </Col>

        {/* Banner */}
        <Col className="col-sm-10 mt-3">
          <Label className="me-4">Banner</Label>
              <input
                type="file"
                name="myfile"
                onChange={async (e) => {
                  if(!id) {
                    showError("First Create Webinar to Upload Banner");
                    return;
                  }
                  if (e.target?.files?.length > 0) {
                    const file = e.target.files[0];
                    const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
                    if (!validImageTypes.includes(file.type)) {
                      showError("Only image files (JPG, PNG, GIF, WEBP) are allowed.");
                      return;
                    }
                    const formData = new FormData();
                    formData.append("pic", file);
                    formData.append("_id", id); 
                    formData.append("module", "webinar"); 
                    formData.append("pic", file);
                    try {
                      const response= await doPOST('/v1/api/file/upload',formData)
                      setData({ ...data, banner:  response?.data?.data?.[0]})
                    } catch (error) {
                      console.error("Error uploading file:", error);
                    }
                  }
                }}
              />
         
           { data?.banner &&  <ImageComponent
              source={data?.banner}
              style={{
                height: "240px",
                width: "240px",
                objectFit: "contain",
              }}
            />}

        </Col>


        {/* GuideLines in its own row */}
        <Col className="col-sm-12 mt-4">
          <Label>GuideLines</Label>
          <DynamicInputField
            values={data?.guideLines ?? ['']}
            placeholder="GuideLines"
            onChange={handleChange}
          />
        </Col>

        {/* Webinar Date */}
        <Col className="col-sm-6 mt-3">
          <Label>Webinar Date</Label>
          <DateSelect
            placeholder="Select Date"
            getTimestamp={true}
            value={data?.date}
            showErr={showErr}
            error={data?.date==null || isNaN(data?.date) ? "Please select date":null}
            onChange={(v) =>{
              setData({ ...data, date:  v })
              setdisabled(true);
            }}
          />
        </Col>

        {/* Active Checkbox */}
        <Col className="col-sm-6 mt-3">
          <div className="d-flex align-items-center mt-4 ms-5">
            <Checkbox
             label="Active"
              checked={data?.active}
              onClick={(v) =>
                setData({
                  ...data,
                  active: !data?.active,
                })
              }
            />
          </div>
        </Col>
      </Row>

      {/* <div className="mt-3 d-flex justify-content-end">
        <SaveButton
          style={{ right: "0%" }}
          onClick={submitForm}
          label={loading ? <ThreeBounce/> : data?._id ? "Update" : "Save"}
          disabled={!data}
        />
            {data?._id && <Button style={{height:30}} className="col-2" color="danger" onClick={()=>removeWebinar(data?._id)}>
              {"Delete"}
            </Button>}
      </div> */}
              <Row className="mt-3 mb-5 col-12">
                <Col sm="12" className="d-flex justify-content-between">
                <Button color="primary" onClick={submitForm}  disabled={!data}>
                    {loading ? <ThreeBounce/> : data?._id ? "Update" : "Create"}
                  </Button>
                  {data?._id && <Button  color="danger" onClick={()=>removeWebinar(data?._id)}>
              {"Delete"}
            </Button>}
                </Col>
              </Row>
          {
            data?._id &&  <Row>
              <Col className="col-12 mt-3">
                <h1 className="fw-bold">Add Sessions</h1>

                <WebinarSession webinarId={id} disableSave={disabled}
                  sessions={sessions} setSessions={setSessions}
                />
              </Col>
            </Row>}


    </CardBody>
  </Card>
</React.Fragment>

  );
};

export default observer(EditWebinar);
