import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import WebinarService from "./WebinarService";
import "./style.css";
import { BiSolidEdit } from "react-icons/bi";
import { toJS } from "mobx";
import moment from "moment";
import ConsultantService from "../Consultant/ConsultantService";
import { truncateDescription } from "../../util/helper";

const Webinar = () => {
  const { t } = useContext(I18nContext);
  const { showError, } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  // const [showViewPage, setShowViewPage] = useState(false);
  // const [searchStatus, setSearchStatus] = useState({
  //   label: "All",
  //   value: "",
  // });

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let filter = {};
      // if (searchStatus?.value) {
      //   filter.status = searchStatus?.value
      // }
      const response = await WebinarService.fetch(filter);
      await ConsultantService.fetch();
    
      console.log(toJS(response));
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [WebinarService?.page, WebinarService?.rows]);

  const color = (ent) => {
    return (
      <>
        <i
          className="bx bxs-circle"
          style={{
            color: ent === true ? "green" : "red",
            fontSize: "13px",
            marginRight: "10px",
            float: "left",
          }}
        />
      </>
    );
  };

  let navigate = useNavigate();

  const changePagination=()=>{
    if(WebinarService.page!=1){
      WebinarService.page=1;
    }
  }

  


  return (
    <>
      <Layout
        // filterStatus
        // statusAction={StatusOptions}
        // searchStatus={searchStatus.label}
        changePagination={changePagination}
        noFilter
        hideTitleBar={true}
        fetchData={fetchData}
        onAddClick={() => {
          navigate("/webinar/edit");
        }}
        addcontent={"Add Webinar"}
        gridLoading={loading}
        rows={WebinarService.rows}
        records={WebinarService.records}
        total={WebinarService.totalRecords}
        page={WebinarService.page}
        // showViewPage={showViewPage}
        onPageChange={async (page, rows) => {
          await WebinarService.onPaginationChange(page, rows);
        }}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>{t("Title")}</Th>
              <Th>{t("Date")}</Th>
              <Th>{t("Description")}</Th>
              <Th>{t("Active")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {WebinarService?.records?.map((webinar) => (
              <Tr key={webinar?._id}>
                <Td >
                  <div className="d-flex align-items-center !important" >
                    <div className="px-2 py-2">{truncateDescription(webinar?.title, 40) ?? ""}</div>
                  </div>
                </Td>
                <Td >
                  <div className="mt-2">
                  {moment.unix(webinar?.date).format("DD MMM YYYY")}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {truncateDescription(webinar?.description, 70)}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                  {color(webinar?.active)}
                  </div>

                </Td>

                {/* <Td >
                  <div className="mt-2">
                    {
                      user?.expiry != undefined ? (
                        (user?.expiry?.toString())?.slice(6, 8) +
                        '-' +
                        (user?.expiry?.toString())?.slice(4, 6) +
                        '-' +
                        (user?.expiry?.toString())?.slice(0, 4)
                      ) : (
                        '--'
                      )}
                  </div>


                </Td>
                <Td >
                  <div className="mt-2">
                    {user?.amount}
                  </div>

                </Td>
                <Td >
                  <div className="mt-2">
                    {WebinarService.usageCount?.[user?.name] ?? 0}
                  </div>

                </Td> */}
                <Td className="float-start justify-content-between">
                  <div className="d-flex align-items-center justify-content-between mt-2" style={{ fontSize: '14px' }}>
                    <div style={{ cursor: 'pointer' }} className="me-3" onClick={() =>
                      navigate(`/webinar/edit/${webinar?._id}`)
                    }>
                      <BiSolidEdit size={18} />
                    </div>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout >
    </>
  );
};

export default observer(Webinar);
