import { observer } from 'mobx-react-lite'
import React from 'react'
import { Layout } from '../../components';
import { useContext } from 'react';
import { I18nContext } from '../../store/context/i18nContext';
import { useState } from 'react';
import PayoutService from './service';
import { DialogContext } from '../../store/context/DialogContext';
import { useEffect } from 'react';
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { useNavigate } from 'react-router-dom';
import { BiSolidEdit } from 'react-icons/bi';
import ConsultantDetail from '../Consultant/ConsultantDetail';
import moment from 'moment';
import { Tooltip } from 'reactstrap';
import { CiCircleInfo } from "react-icons/ci";
import { doPOST } from '../../util/httpUtil';



const Payout = () => {
    const { t } = useContext(I18nContext);
    const { showError, showConfirm, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const fetchData = async (search = "") => {
        if (loading) return;
        setLoading(true);
        try {
            let filter = {};
            if (search) filter.search = search
            const response = await PayoutService.fetch(filter);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData(search);
    }, [PayoutService?.page, PayoutService?.rows]);

    const changePagination = () => {
        if (PayoutService.page != 1) {
            PayoutService.page = 1;
        }
    }

    const createPayout = async () => {
        try {
            const confirmation = await showConfirm({
                title: `You want to create Payout for all the consultants for the  ${moment().subtract(1, 'month').format('MMMM')} month ?`,
                description: (<>
                    <p>
                        You are about to create payouts for the selected month: <strong>{moment().subtract(1, 'month').format('MMMM')}</strong>.
                    </p>
                    <p>
                        This action will:
                        <ul>
                            <li>Mark all users with due payments as paid for this month.</li>
                            <li>Generate Disbursals for all consultants with due payments.</li>
                        </ul>
                    </p>
                    <p>Are you sure you want to proceed?</p>
                </>)
            })
            if (confirmation) {
                const response = await doPOST('/v1/api/payout/create-all', {
                    month: moment().subtract(1, 'month').format('YYYYMM')
                });
                if (response.status === 200) {
                    showMessage("success", "Payout Created Successfully");
                    fetchData(search);
                }
            }
        } catch (error) {

        }

    };

    return (
        <Layout
            changePagination={changePagination}
            noFilter
            hideTitleBar={true}
            filterInput={true}
            onAddClick={createPayout}
            gridLoading={loading}
            rows={PayoutService.rows}
            records={PayoutService.records}
            total={PayoutService.totalRecords}
            page={PayoutService.page}
            showViewPage={true}
            search1={search}
            setSearch1={setSearch}
            fetchData={(d) => {
                fetchData(search);
            }}
            onPageChange={async (page, rows) => {
                await PayoutService.onPaginationChange(page, rows);
            }}
            addcontent={"Create Payout"}
        >
            
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>{t("Consultant")}</Th>
                            <Th>{t("Total Amount Paid")}</Th>
                            {/* <Th>{t("Total Amount Due (for Billing Created ")}</Th> */}
                            <span id="totalAmountDueTooltip">
                                <Th>{t('Total Amount Due')} <CiCircleInfo size={20} color='#333' /></Th>
                            </span>
                            <Tooltip
                                isOpen={tooltipOpen}
                                target="totalAmountDueTooltip"
                                toggle={toggleTooltip}
                            >
                                {t('This amount represents dues for billing records that have already been created.')}
                            </Tooltip>
                            <Th>{t("Currency")}</Th>
                            <Th>{t("Last Transaction Date")}</Th>
                            <Th>{t("Actions")}</Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>
                {PayoutService?.records?.length ? 
                <Layout.TbodyFull>
                    <Tbody>
                        {PayoutService?.records?.map((record) => (
                            <Tr key={record?._id}>
                                <Td >
                                    <div className="d-flex align-items-center !important" >
                                        <ConsultantDetail _id={record?.consultant_id} />
                                    </div>
                                </Td>
                                <Td >
                                    <div className="mt-2">
                                        {record.paid ?? 0}
                                    </div>

                                </Td>
                                <Td >
                                    <div className="mt-2">
                                        {record.due ?? 0}
                                    </div>
                                </Td>
                                <Td >
                                    <div className="mt-2">
                                        {record.currency ?? "AED"}
                                    </div>
                                </Td>
                                <Td >
                                    <div className="mt-2">
                                        {record?.createdAt ? moment(record?.createdAt).format("DD-MM-YYYY") : "-"}
                                    </div>
                                </Td>

                                <Td className="float-start justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between mt-2" style={{ fontSize: '14px' }}>
                                        <div style={{ cursor: 'pointer' }} className="me-3" onClick={() =>
                                            navigate(`/payout/detail?consultant_id=${record?.consultant_id}`)
                                        }>
                                            <BiSolidEdit size={18} />
                                        </div>
                                    </div>
                                </Td>
                            </Tr>
                        ))}

                    </Tbody>
                </Layout.TbodyFull>
             :
                (<Layout.TbodyFull>
                    <Tr className="text-center py-4 m-auto ">
                       <Td colSpan={12} className="py-3 fw-bold" >{t('No payouts found. Please add a new payout.')}</Td> 
                    </Tr>
                </Layout.TbodyFull>)}
        </Layout >
    )
}

export default observer(Payout)