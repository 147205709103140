import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import ConsultantService from "../../../pages/Consultant/ConsultantService"; // Ensure this path is correct
import { toJS } from "mobx";
import useDebounce from "../../../hooks/useDebounce";
import ImageComponent from "../../Image/ImageComponent";

const ConsultantSelect = ({
  label = "Select Consultant",
  placeholder = "Search consultants...",
  onChange,
  value, // Passed as only id
  isMulti = false,
  additionalFilters = {},
  styles = {},
  ...props
}) => {
  const [options, setOptions] = useState(ConsultantService?.consultantKeyValue || []);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  const formatOption = (consultant) => ({
    label: <div className="d-flex gap-5  align-items-center"><ImageComponent
    source={consultant?.profileImage}
    style={{
      height: "50px",
      width: "50px",
      objectFit: "contain",
      borderRadius:25
    }}
  />{consultant.displayName}</div>, 
    displayName: consultant?.label ?? consultant?.displayName,
    fname: consultant?.fname,
    lname: consultant?.lname,
    value: consultant?.value ?? consultant?._id,
    image:consultant?.profileImage
  });

  const fetchConsultants = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const filter = { consultant: searchTerm, page, ...additionalFilters };
      const data = await ConsultantService.fetch(filter);

      const newOptions = (data?.data?.rows || []).map(formatOption);

      setOptions((prev) => {
        const seen = new Set(prev.map((option) => option.value));
        return [
          ...prev,
          ...newOptions.filter((option) => !seen.has(option.value)), // Add only unique options
        ];
      });

      setHasMore(newOptions.length > 0); // If no new options, stop further loading
    } catch (error) {
      console.error("Error fetching consultants:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFetch = useDebounce(fetchConsultants, 300);

  useEffect(() => {
    debounceFetch();
  }, [searchTerm, page]);

  useEffect(() => {
    if (value && !options.some((option) => option.value === value)) {
      ConsultantService.get(value).then((data) => {
        if (data) {
          const newOption = formatOption(data);
          setOptions((prevOptions) => [...prevOptions, newOption]);
          setSelectedOption(newOption);
        }
      });
    } else {
      const existingOption = options.find((option) => option.value === value);
      setSelectedOption(existingOption || null);
    }
  }, [value, options]);

  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
    setPage(1); // Reset page for a new search
  };

  const handleMenuScrollToBottom = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
      ConsultantService.onPaginationChange(page+1, ConsultantService.rows );
    }
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected);
  };

  const filterOption = useCallback((option, inputValue) => {
    const { fname, lname, label, displayName } = option.data;
    const normalizedInput = inputValue.toLowerCase();
    return (
      fname?.toLowerCase().includes(normalizedInput) ||
      lname?.toLowerCase().includes(normalizedInput) ||
      displayName?.toLowerCase().includes(normalizedInput)
    );
  }, []);

  return (
    <div>
      <label>{label}</label>
      <Select
        {...props}
        isLoading={loading}
        options={options}
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={selectedOption}
        filterOption={filterOption}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: 40,
            ...styles.control,
          }),
          menu: (base) => ({
            ...base,
            zIndex: 9999,
            ...styles.menu,
          }),
          ...styles,
        }}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        placeholder={placeholder}
      />
            {
        props?.error && props?.showErr && (
          <em className="inputfield__error">
            {props?.error ? props?.error : "Please enter Something"}
          </em>
        )
      }

    </div>
  );
};

export default ConsultantSelect;
