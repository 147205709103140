
import React, { useState, useEffect } from 'react';
import { doPOST } from '../../util/httpUtil';
import moment from 'moment';
import { Spinner, Button } from 'reactstrap';
import TransactionDetailsModal from './TransactionDetailsModal';

const DisbursalTab = ({ consultant_id }) => {
    const [disbursalHistory, setDisbursalHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchDisbursals = async () => {
        try {
            const res = await doPOST('/v1/api/payout/disbursal/history', { consultant_id });
            setDisbursalHistory(res?.data?.data || []);
        } catch (error) {
            console.error('Error fetching disbursal history:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (consultant_id) {
            fetchDisbursals();
        }
    }, [consultant_id]);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleViewDetails = (transaction) => {
        setSelectedTransaction(transaction);
        toggleModal();
    };

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Spinner color="primary" />
        </div>;
    }

    return (
        <div className='d-flex flex-grow-1 flex-column p-lg-4'>
            <h4 className="mb-5 fw-bold text-center fs-3">Disbursal History</h4>
            {disbursalHistory?.length ? (
                <table className="table table-bordered table-striped shadow">
                    <thead>
                        <tr>
                            <th className='py-2'>Date</th>
                            <th className='py-2'>Amount (AED)</th>
                            <th className='py-2'>Months</th>
                            <th className='py-2'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {disbursalHistory.map((d) => (
                            <tr key={d._id}>
                                <td className='py-2'>
                                    {d.disbursal_date ? moment.unix(d.disbursal_date).format('DD/MM/YYYY') : '-'}
                                </td>
                                <td className='py-2'>{d.disbursal_amount || '-'}</td>
                                <td className='py-2'>
                                    {d.billing_ids?.length
                                        ? d.billing_ids.map(billing =>
                                            moment(billing.month.toString(), 'YYYYMM').format('MMM, YYYY')
                                        ).join(', ')
                                        : '-'}
                                </td>
                                <td className='py-2'>
                                    <Button
                                        color="link"
                                        size="sm"
                                        onClick={() => handleViewDetails(d)}
                                    >
                                        View Details
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="alert alert-info text-center">No Disbursals found.</div>
            )}

            <TransactionDetailsModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                transaction={selectedTransaction}
            />
        </div>
    );
};

export default DisbursalTab;





// import React, { useState, useEffect } from 'react';
// import { doPOST } from '../../util/httpUtil';
// import moment from 'moment';
// import { Spinner, Button } from 'reactstrap';
// import ReceiptModal from './ReceiptModal';

// const DisbursalTab = ({ consultant_id }) => {
//     const [disbursalHistory, setDisbursalHistory] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [selectedReceipts, setSelectedReceipts] = useState([]);
//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const fetchDisbursals = async () => {
//         try {
//             const res = await doPOST('/v1/api/payout/disbursal/history', { consultant_id });
//             setDisbursalHistory(res?.data?.data || []);
//         } catch (error) {
//             console.error('Error fetching disbursal history:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (consultant_id) {
//             fetchDisbursals();
//         }
//     }, [consultant_id]);

//     const toggleModal = () => setIsModalOpen(!isModalOpen);

//     const handleViewReceipts = (receipts) => {
//         setSelectedReceipts(receipts || []);
//         toggleModal();
//     };

//     if (loading) {
//         return <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
//             <Spinner color="primary" />
//         </div>;
//     }

//     return (
//         <div className='d-flex flex-grow-1 flex-column p-lg-4'>
//             <h4 className="mb-5 fw-bold text-center fs-3">Disbursal History</h4>
//             {disbursalHistory?.length ? (
//                 <table className="table table-bordered table-striped shadow">
//                     <thead>
//                         <tr>
//                             <th className='py-2'>Date</th>
//                             <th className='py-2'>Amount (AED)</th>
//                             <th className='py-2'>Months</th>
//                             <th className='py-2'>Notes</th>
//                             <th className='py-2'>Receipts</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {disbursalHistory.map((d) => (
//                             <tr key={d._id}>
//                                 <td className='py-2'>
//                                     {d.disbursal_date ? moment.unix(d.disbursal_date).format('DD/MM/YYYY') : '-'}
//                                 </td>
//                                 <td className='py-2'>{d.disbursal_amount || '-'}</td>
//                                 <td className='py-2'>
//                                     {d.billing_ids?.length
//                                         ? d.billing_ids.map(billing =>
//                                             moment(billing.month.toString(), 'YYYYMM').format('MMM, YYYY')
//                                         ).join(', ')
//                                         : '-'}
//                                 </td>
//                                 <td className='py-2'>{d.extra_details?.notes || 'No notes available'}</td>
//                                 <td className='py-2'>
//                                     {d.extra_details?.receipts?.length > 0 ? (
//                                         <Button
//                                             color="link"
//                                             size="sm"
//                                             onClick={() => handleViewReceipts(d.extra_details.receipts)}
//                                         >
//                                             View Receipts
//                                         </Button>
//                                     ) : (
//                                         'No receipts uploaded'
//                                     )}
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             ) : (
//                 <div className="alert alert-info text-center">No Disbursals found.</div>
//             )}

//             <ReceiptModal
//                 isOpen={isModalOpen}
//                 toggle={toggleModal}
//                 receipts={selectedReceipts}
//             />
//         </div>
//     );
// };

// export default DisbursalTab;
