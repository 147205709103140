import { makeAutoObservable } from "mobx"; 
import { doGET, doPOST } from "../../util/httpUtil";
import { ENDPOINTS } from "../../services/api/constants";

class Service {
  records = [];
  totalRecords = 0;
  STRUCTURE = [];
  page = 1;
  rows = 20;
  filterOption = '';
  error = "";
  downloadFilterOption = '';

  constructor() {
    makeAutoObservable(this);
  }

  resetData = () => {
    this.page = 1;
    this.rows = 20;
    this.records = [];
    this.totalRecords = 0;
  }

  fetch = async (filter) => {
    const queryParams = new URLSearchParams(filter);
    queryParams.append('page', String(this.page));
    queryParams.append('rows', String(this.rows));
    const apiUrl = ENDPOINTS.grid('payout',queryParams.toString());

    const response = await doGET(apiUrl);
    if (response.status === 200) {
      this.records = response.data?.data?.rows;
      if(response?.data?.data?.total){
          this.totalRecords = response?.data?.data?.total;
      }
    }
    return response?.data
  };
  
  fetchrequested = async (filter) => {
    const response = await doGET(
      ENDPOINTS.gridrequested(this.page, this.rows, filter));
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
    return response?.data
  }
  fetchrejected = async (filter) => {
    const response = await doGET(
      ENDPOINTS.gridrejected(this.page, this.rows, filter));
    if (response.status === 200) {
      this.records = response.data.rows;
      this.totalRecords = response.data.records;
    }
    return response?.data
  }

  onPaginationChange = async (page, rows, type) => {
    this.page = page;
    this.rows = rows;
    this.fetch();
  };

  edit = async function (data, id) {
    const response = await doPOST(ENDPOINTS?.edit(id), data);

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    await this.fetch();
    return response?.data?._id;
  };

  approve = async function (id, val) {
    console.log(val);
    const response = await doPOST(ENDPOINTS.approve(id), val);
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rows, type) => {
    this.page = page;
    this.rows = rows;
    if (type === "approved") {

      await this.fetch();
    }
    if (type === "requested") {
      await this.fetchrequested();
    }
    if (type === "rejected") {
      await this.fetchrejected();
    }
  };
}

const PayoutService = new Service();
export default PayoutService;
