import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import FaqService from '../../pages/Faqs/FaqService';
import { FaChevronDown, FaChevronRight, FaPen, FaPlus, FaSpinner, FaTrash } from 'react-icons/fa6';
import "./style.scss"

const FolderItemModule = ({ isCollapsed, category, onToggleCollapse, onCreateSubCategory }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editHeading, setEditHeading] = useState(category?.heading);
    const [editDescription, setEditDescription] = useState(category?.description);
    const [editAction, setEditAction] = useState(category?.action);
    const [isAddingChild, setIsAddingChild] = useState(false);
    const [heading, setHeading] = useState("");
    const [desc, setDesc] = useState("");
    const [action, setAction] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const handleEdit = async () => {
        try {
            setIsProcessing(true);
            await FaqService.edit({ ...category, heading: editHeading, description: editDescription, action: editAction });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating category:", error);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleAddChild = async () => {
        if (!heading || !desc) {
            setIsAddingChild(false);
            return;
        }
        try {
            setIsProcessing(true);
            await onCreateSubCategory(heading, desc, action, category?._id);
            setIsAddingChild(false);
            setHeading("");
            setDesc("");
            setAction("");
        } catch (error) {
            console.error("Error adding subcategory:", error);
        } finally {
            setIsProcessing(false);
        }
    };

    const onBlur = () => {
        setHeading("");
        setDesc("");
        setAction("");
        setIsAddingChild(false)
    }

    return (
        <div key={category?._id} className="folder-item">
            <div className="folder-header d-flex align-items-center">
                <span className="me-2" onClick={onToggleCollapse}>
                    {category.children && category.children.length > 0 ? (
                        isCollapsed ? <FaChevronRight /> : <FaChevronDown />
                    ) : null}
                </span>

                {isEditing ? (
                    <div className='d-flex'>
                        <input
                            autoFocus={true}
                            type="text"
                            value={editHeading}
                            onChange={(e) => {
                                setEditHeading(e.target.value)
                            }
                            }
                            style={{ flex: 1 }}
                            onKeyDown={(e) => e.key === "Enter" && handleEdit()}
                            placeholder='Heading'
                            // onBlur={handleEdit}
                            className='category-creation-input'
                        />
                        <input
                            type="text"
                            value={editDescription}
                            onChange={(e) => {
                                setEditDescription(e.target.value)
                            }
                            }
                            onKeyDown={(e) => e.key === "Enter" && handleEdit()}
                            style={{ flex: 1 }}
                            placeholder='Description'
                            // onBlur={handleEdit}
                            className='category-creation-input'
                        />
                        <input
                            type="text"
                            value={editAction}
                            onChange={(e) => {
                                setEditAction(e.target.value)
                            }
                            }
                            // onBlur={handleEdit}
                            onKeyDown={(e) => e.key === "Enter" && handleEdit()}
                            style={{ flex: 1 }}
                            placeholder='Action'
                            className='category-creation-input'
                        />
                    </div>
                ) : (
                    <div className='flex-column'>
                        <p className='p-0 m-0 fw-bold'>{category.heading}</p>
                        <p className='p-0 m-0'>{category.description}</p>
                    </div>
                )}
                <div className="icon-container ms-auto d-flex align-items-center">
                    {
                        isProcessing ? (
                            <div className="processing-indicator">
                                <FaSpinner className="spinner" />
                            </div>

                        ) : <FaPen className='mx-2' onClick={() => {
                            if(isEditing === true) handleEdit();
                            else setIsEditing(true);
                        }} />
                    }
                    {
                        isProcessing ? (
                            <div></div>
                        ) : <FaPlus className='mx-2' onClick={() => setIsAddingChild(true)} />
                    }
                    {isProcessing ? (
                        <div></div>

                    ) :
                        <FaTrash className='mx-2' onClick={async () => {
                            try {
                                setIsProcessing(true);
                                await FaqService.delete(category._id);
                            } catch (error) {
                                console.error("Error deleting category:", error);
                            } finally {
                                setIsProcessing(false);
                            }
                        }} />
                    }
                </div>
            </div>
            {isAddingChild && (
                <div className='d-flex'>
                    <input
                        autoFocus={true}
                        type="text"
                        placeholder="Heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleAddChild()}
                        className="mt-2 child-folder-input category-creation-input"
                        style={{ flex: 1 }}
                        // onBlur={onBlur}
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleAddChild()}
                        className="mt-2 child-folder-input category-creation-input"
                        style={{ flex: 1 }}
                        // onBlur={onBlur}
                    />
                    <input
                        type="text"
                        placeholder="Action"
                        value={action}
                        onChange={(e) => setAction(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleAddChild()}
                        className="mt-2 child-folder-input category-creation-input"
                        style={{ flex: 1 }}
                        // onBlur={onBlur}
                    />
                </div>
            )}

        </div>
    );
};

export default observer(FolderItemModule);