import React, { useEffect, useState, useRef, useMemo,useContext } from "react";
import { ENDPOINTS, API_METHODS } from "../../common/utils/Constants";
import { ApiHandler } from "../apiHandler/apiHandler";
import { useLocation } from "react-router-dom";
import { doGET } from "../../util/httpUtil";

export const UserContext = React.createContext();


export const UserProvider = (props) => {
  const [authToken, setAuthToken] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [user, setUser] = useState(null);
  const [languages, setLanguages] = useState([{}]);
  const [tags, setTags] = useState([{}]);
  const [skills, setSkills] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [cart, setCart] = useState({ items: [], address: {} });
  const [corpId, setCorpId] = useState(null);
  const [roles, setRoles] = useState(null);
  const socketRef = useRef(null);
  //peer
  const peerRef = useRef(null);
  //peerCall
  const supportCallRef = useRef({
    role: undefined,
    whoAmI: undefined,
    otherPeerUser: null,
  });
  const supportCallDataRef = useRef({});
 

  // -calling Status
  // 	not-connected
  // 	waiting-for-call
  // 	calling
  // 	call-connected

  // -screen sharing Status
  // 	not-stared
  // 	waiting-for-ask
  // 	asking
  // 	screen-share-started



  

  const [supportCallStatusForUser, setSupportCallStatusForUser] = useState("not-connected");
  const [supportCallStatusForAdmin, setSupportCallStatusForAdmin] = useState("not-connected");
  const [supportCallScreenShareStatus, setSupportCallScreenShareStatus] = useState("not-started");

  const [supportCallStatusForUserSetter, setSupportCallStatusForUserSetter] = useState("not-connected")
  const [supportCallStatusForAdminSetter, setSupportCallStatusForAdminSetter] = useState("not-connected")
  const [supportCallScreenShareStatusSetter, setsupportCallScreenShareStatusSetter] = useState("not-started");



 const  getLanguages = async () => {
  const response = await doGET(`/api/o/languages`);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  }
 
  const  getTags = async () => {
    const response = await doGET(`/api/consultant/tags`)
    if (response.status === 200) {
      return response.data?.rows ? response.data?.rows : response;
    }
  }

  const getSkills = async () => {
    const response = await doGET(`/api/services/list`,)
    if (response.status === 200) {
      return response.data?.rows ? response.data?.rows : response;
    }
  };

  const getFullSkill = async () => {
    const response = await doGET(`/v1/api/category/grid?rows=-1`,)
    // console.log(response?.data)
    if (response?.data.success === true) {
      return response?.data?.data?.rows? response?.data?.data?.rows : response;
    }
  };


  const fetchLanguages = async () => {
    try {
      setShowLoader(true);
      const response = await getLanguages();
      if(response?.length){
        let fetchedLanguages=[];
        response?.map((language)=>{
          fetchedLanguages.push({
            ...language,
         label:language?.name?language?.name:"",
          value:language?._id?language?._id:"",
       })
        })
        setLanguages(fetchedLanguages)
      } 
    } catch (e) {}
      setShowLoader(false);
    }
  
  const fetchTags = async () => {
    try {
      setShowLoader(true);
      const response = await getTags();
      if(response?.length){
        let fetchedTags=[];
        response?.map((tag)=>{
          fetchedTags.push({
            ...tag,
         label:tag?.name?tag?.name:"",
          value:tag?._id?tag?._id:"",
       })
        })
        setTags(fetchedTags)
      } 
    } catch (e) {}
      setShowLoader(false);
  };

  const fetchSkills = async () => {
    try {
      setShowLoader(true);
     const  response = await getSkills();
     if(response?.length){
      let fetchedSkills=[];
      response?.map((skill)=>{
        fetchedSkills.push({
          ...skill,
       label:skill?.name?skill?.name:"",
        value:skill?.name?skill?.name:"",
     })
      })
            setSkills(fetchedSkills)
    } 
    } catch (error) { }
    setShowLoader(false);
  };

  const fetchAllSkills = async () => {
    try {
      setShowLoader(true);
     const  response = await getFullSkill();
    //  console.log(response)
     if(response?.length){
      let fetchedAllSkills=[];
      response?.map((skill)=>{
        fetchedAllSkills.push({
          ...skill,
       label:skill?.name?skill?.name:"",
        value:skill?._id?skill?._id:"",
     })
      })
          setAllSkills(fetchedAllSkills)
    } 
    } catch (error) { }
    setShowLoader(false);
  };

  useEffect(()=>{
    if(isLoggedIn){
    fetchLanguages();
    fetchSkills();
    fetchAllSkills();
    fetchTags();
    }
  },[isLoggedIn])
  useEffect(() => {
    setSupportCallStatusForAdmin(supportCallStatusForAdminSetter)
  }, [supportCallStatusForAdminSetter])

  useEffect(() => {
    setSupportCallStatusForUser(supportCallStatusForUserSetter)
  }, [supportCallStatusForUserSetter])

  useEffect(() => {
    setSupportCallScreenShareStatus(supportCallScreenShareStatusSetter)
  }, [supportCallScreenShareStatusSetter])

  //support force disconnect on app close for extra
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.off();
        socketRef.current = null;
      }
    };
  }, []);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);


  useEffect(() => {
    setRoles(localStorage.getItem("roles"));
  }, [])

  const adminLogin = async (data) => {
    try {
      const response = await ApiHandler({
        reqParam: data,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.adminLogin,
      });
      console.log(response.data)
      setUser(response?.data?.userDetails)
      if (response.status === 200) {

        setAuthToken(response.data.token);
        setIsLoggedIn(true);
        localStorage.setItem("name", response.data.name)
        localStorage.setItem("profileImage", response.data.userDetails?.profileImage)
        setRoles(JSON.stringify(response.data.userDetails?.roles));

        localStorage.setItem("roles", JSON.stringify(response.data.userDetails?.roles))
        setIsAdmin(true);
        return response;
      } else {
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const logout = async () => {
    try {
      localStorage.removeItem("token");
      await doGET(ENDPOINTS.logout)
    } catch (err) {
    }
  }
  return (
    <UserContext.Provider
      value={{
        query,
        authToken,
        adminName,
        setAdminName,
        user,
        setUser,
        setAuthToken,
        isLoggedIn,
        setIsLoggedIn,
        skills,
        allSkills,
        languages,
        tags,
        setRoles,
        showLoader,
        setShowLoader,
        cart,
        adminLogin,
        setCart,
        logout,
        isAdmin,
        //call peer and socket
        peerRef,
        socketRef,
        supportCallRef,
        supportCallDataRef,
        supportCallScreenShareStatus,
        setsupportCallScreenShareStatusSetter,
        supportCallStatusForUser,
        setSupportCallStatusForUserSetter,
        supportCallStatusForAdmin,
        setSupportCallStatusForAdminSetter,
        corpId,
      }}
    >
      {props?.children}
    </UserContext.Provider>
  );
};
