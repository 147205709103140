import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import ConsultantSelect from "../SelectField/ConsultantSelect/ConsultantSelect";
import { InputField } from "../InputField/InputField";
import DynamicInputField from "../InputField/DynamicInputField";
import TimeSelect from "../DateSelects/TimeSelect";
import { formatTime } from "./Util";
import WebinarService from "../../pages/Webinar/WebinarService";
import { ENDPOINTS } from "../../common/utils/Constants";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/httpUtil";
import moment from "moment";
import { DialogContext } from "../../store/context/DialogContext";
import { ThreeBounce } from "better-react-spinkit";
import { parsebetweenHHMMTimestamp } from "../../util/helper";


const WebinarSessionCard = ({ webinarId, onSave, onDelete, data, disableSave }) => {
  const [sessionData, setSessionData] = useState(data);
    const { showError } = useContext(DialogContext);
    const [showErr, setShowErr]= useState(false);
    const[loading, setloading]= useState(false);

  const handleInputChange = (field, value) => {
    setSessionData({ ...sessionData, [field]: value });
  };

  const handleSave = async () => {
    if(loading) return;
    if (
      sessionData?.title?.length === 0 || 
      sessionData?.description?.length === 0 || 
      !sessionData?.consultant || !sessionData?.startTime || !sessionData?.endTime 
    ) {
      setShowErr(true);
      showError("Please fill all required fields");
      return;
    }
  
    // Fetch webinar data
    let webinarData = {};
    try {
      webinarData = await WebinarService.get(webinarId);
    } catch (error) {
      setShowErr(true);
      showError("Failed to fetch webinar data: " + error);
      return;
    }
  
    const startTimestamp = moment.unix(webinarData?.data?.date)
      .startOf('day')
      .add(parsebetweenHHMMTimestamp(sessionData.startTime).hours(), 'hours')
      .add(parsebetweenHHMMTimestamp(sessionData.startTime).minutes(), 'minutes')
      .unix();
  
    const endTimestamp = moment.unix(webinarData?.data?.date)
      .startOf('day')
      .add(parsebetweenHHMMTimestamp(sessionData.endTime).hours(), 'hours')
      .add(parsebetweenHHMMTimestamp(sessionData.endTime).minutes(), 'minutes')
      .unix();
  
    sessionData.startTime = startTimestamp;
    sessionData.endTime = endTimestamp;
  
    // Check if start time is greater than end time
    if (sessionData?.startTime > sessionData?.endTime) {
      setShowErr(true);
      showError("Start Time can't be greater than End Time");
      return;
    }
  
    // Save the data if everything is valid
    setloading(true);
    setShowErr(false);
    try {
      await onSave(sessionData);
    } catch (error) {
      console.error(error);
      showError(error || "An error occurred");
    } finally {
      setloading(false);
    }
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <Row>
          <Col sm="6">
            {/* {sessionData.consultant} */}
            <ConsultantSelect
              value={sessionData.consultant}
              onChange={(v) => handleInputChange("consultant", v?.value)}
              showErr={showErr}
              error={sessionData?.consultant==null || sessionData?.consultant==undefined  ? "Please Select Consultant":null}
            />
          </Col>
          <Col sm="6">
            <Label>Title</Label>
            <InputField
              placeholder="Title"
              value={sessionData.title}
              onChange={(v) => handleInputChange("title", v)}
              showErr={showErr}
              error={(sessionData?.title?.length==0 || sessionData?.title==null) ? "Please Fill Title":null}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <Label>Description</Label>
            <textarea
                  className="col-sm-12"
                  placeholder="Enter Description"
                  value={sessionData?.description}
                  onChange={(v) => handleInputChange("description", v?.target?.value)}
                  rows={8}
                  cols={160}
                  style={{ border: "1px solid #ced4da", outline: "none" }}
                />
            
          </Col>
          {/* <Col sm="6">
            <Label>Join Link</Label>
            <InputField
              isDisabled={true}
              placeholder="Join Link"
              value={sessionData.joinLink}
              onChange={(v) => handleInputChange("joinLink", v)}
            />
          </Col> */}
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <Label>Start Time</Label>
            <TimeSelect
              value={moment.unix(sessionData?.startTime).format("HH:mm")}
              onChange={(values) =>
                {
                   handleInputChange("startTime", values)}
                }
            />

          </Col>
          <Col sm="6">
            <Label>End Time</Label>
            <TimeSelect
              value={moment.unix(sessionData?.endTime).format("HH:mm")}
              onChange={(values) => 
              {  
                 handleInputChange("endTime", values)}
              }
            />

          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="12">
            <Label>Features</Label>
            <DynamicInputField
              placeholder={"Features"}
              values={sessionData.features ?? [""]}
              onChange={(values) => handleInputChange("features", values)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="12" className="d-flex justify-content-between">
          <Button color="primary" onClick={()=>{
              if(disableSave){
                showError("You have changed Webinar Date save it first");
                return;
              }
              handleSave()
            }}>
              {loading ?<ThreeBounce color={"#fff"}/> :sessionData?._id ? "Update" : "Create"}
            </Button>
            {sessionData?._id  &&  <Button color="danger" onClick={onDelete}>
             {loading ?<ThreeBounce color={"#fff"}/> :"Delete"}
            </Button>}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const WebinarSession = ({ webinarId, disableSave=false , sessions, setSessions }) => {
  
  const { showError, showMessage } = useContext(DialogContext);

  const addSession = () => {
    setSessions((prevSessions) => [...prevSessions, {}]);
  };


  useEffect(() => {
    setSessions(WebinarService?.sessionRecord)
  }, [WebinarService?.sessionRecord?.length])
  

  const removeSession =  async (index) => {
    try {

      let response= await doDELETE(ENDPOINTS.delete("webinar-session", sessions[index]?._id));
      if(response?.status==200){
        showMessage("success","Session Deleted Successfully")
      }
    } catch (error) {
      console.error("Error Deleting session:", error);
      showError(error);
    }
    setSessions((prevSessions) => prevSessions.filter((_, i) => i !== index));
  };


  const saveSession = async (index, sessionData) => {
    try {
      const updatedSessions = [...sessions];
      updatedSessions[index] = sessionData; 
      let response = {};
  
      if (updatedSessions[index]?._id) {
       let response= await doPUT(ENDPOINTS.update('webinar-session'), { ...updatedSessions[index] });
       if(response.status==200){
        showMessage("success","Session Updated Successfully")
       }

      } else {
        response = await doPOST(ENDPOINTS.create('webinar-session'), { ...updatedSessions[index], webinarId });
        if(response.status==200){
          showMessage("success","Session Created Successfully")
         }
        if (response?.data?.data?._id) {

          const updatedSessionData = await doGET(ENDPOINTS.get("webinar-session", response?.data?.data?._id));

          await WebinarService.fetchSessions(null,webinarId);
          setSessions(WebinarService?.sessionRecord)
  
          if (updatedSessionData?.data?.data) {
            const newSessionData = updatedSessionData?.data?.data;
  

            updatedSessions[index] = newSessionData;
            setSessions([...updatedSessions]); 
          }
        }
      }
    } catch (error) {
      console.error("Error saving session:", error);
      showError(error);
    }
  };
  
  

  return (
    <div>
      {sessions?.map((session, index) => (
        <WebinarSessionCard
          key={session._id || index} 
          data={session}
          webinarId={webinarId}
          onSave={(data) => saveSession(index, data)}
          onDelete={() => removeSession(index)}
          disableSave={disableSave}
        />
      ))}

      <Button color="success" onClick={addSession} className="mt-3">
        + Add Session
      </Button>
    </div>
  );
};


export default WebinarSession;
