import React, { useEffect, useState } from 'react'
import CategoryService from '../../../pages/Category/CategoryService';
import { toJS } from 'mobx';
import { Label } from 'reactstrap';
import { SelectField } from '../SelectField';

const ServiceCategorySelect = ({
    label = '',
    placeholder = '',
    onChange = () => { },
    value = '',
    isRequired = false,
}) => {
    const [data, setData] = useState([]);
    const [val, setVal] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await CategoryService.fetchServiceCategories();
                setData(toJS(response))
            } catch (error) {

            }
        };

        fetchData();
    }, [])

    useEffect(() => {
        const res = data?.find(e => e?.value === value);
        setVal(res?.value)
    }, [value, data])
    return (
        <div>
            <Label>{label}<font color="#EC734B">{isRequired ? '*' : ""}</font></Label>
            <SelectField
                data={data}
                placeholder={placeholder}
                value={val}
                onChange={(v) => onChange(v)}
            />
        </div>
    )
}

export default ServiceCategorySelect;