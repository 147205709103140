import React, { useState } from "react";
import BillingTab from "./BillingTab";
import DisbursalTab from "./DisbursalTab";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./style.css"

const PayoutDetail = () => {
    const [activeTab, setActiveTab] = useState("billings");
    const [consulantId,setconsulantId]=useState(null);
    const location=useLocation();

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        const consultant = searchParams.get("consultant_id");
        setconsulantId(consultant);
    },[location.search])
 

    return (
        <div className="scroll-content mt-4 px-4">


            <ul className="nav nav-tabs mb-4 sticky-top">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'billings' ? 'active' : ''}`}
                        onClick={() => setActiveTab('billings')}
                    >
                        Consultant Billings
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'disbursals' ? 'active' : ''}`}
                        onClick={() => setActiveTab('disbursals')}
                    >
                        Disbursal
                    </button>
                </li>
            </ul>

            {activeTab === 'billings' ? (
                <BillingTab consultant_id={consulantId} toggleTab={()=>setActiveTab('disbursals')}/>
            ) : (
                <DisbursalTab consultant_id={consulantId} />
            )}


        </div>
    );
};

export default PayoutDetail;
