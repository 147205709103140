import React, { useEffect } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { IoAddCircleSharp } from "react-icons/io5";
import { GrSubtractCircle } from "react-icons/gr";
import { FaMinusCircle } from "react-icons/fa";

const DynamicInputField = ({ values, onChange, placeholder = "Field" }) => {
  useEffect(() => {
    if (values.length === 0) {
      onChange([""]);
    }
  }, [values, onChange]);

  const handleAddField = () => {
    onChange([...values, ""]);
  };

  const handleRemoveField = (index) => {
    if (values.length > 1) {
      const updatedValues = [...values];
      updatedValues.splice(index, 1);
      onChange(updatedValues);
    }
  };

  const handleChange = (index, value) => {
    const updatedValues = [...values];
    updatedValues[index] = value;
    onChange(updatedValues);
  };

  return (
    <div>
      {values?.map((value, index) => (
        <Row key={index} className="mt-3">
          <Col className="col-10">
            <Input
              type="text"
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              placeholder={`Enter ${placeholder}`}
            />
          </Col>
          <Col xs="auto" className="gap-5">
          {/* <FaMinusCircle /> */}
          {values.length > 1 && (
              <FaMinusCircle
                color="red"
                size={22}
                onClick={() => handleRemoveField(index)}
              />
            )}
            {index === values.length - 1 && (
              <IoAddCircleSharp color="green" size={28} onClick={handleAddField} />
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default DynamicInputField;
