import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ImageComponent from '../../components/Image/ImageComponent';

const TransactionDetailsModal = ({ isOpen, toggle, transaction }) => {
    if (!transaction) return null;

    const { extra_details } = transaction;

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
            <ModalBody>
                {/* Notes Section */}
                {extra_details?.notes && (
                    <div>
                        <h5>Notes</h5>
                        <p>{extra_details.notes}</p>
                    </div>
                )}

                {/* Receipts Section */}
                {extra_details?.receipts?.length > 0 && (
                    <div className="mt-4">
                        <h5>Receipts</h5>
                        <div className="d-flex flex-wrap gap-3 justify-content-center">
                            {extra_details.receipts.map((receipt, index) => (
                                <div key={index} className="d-flex flex-column align-items-center">
                                    <ImageComponent
                                        source={receipt}
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <a
                                        href={receipt}
                                        download={`receipt_${index + 1}.png`}
                                        className="btn btn-sm btn-outline-primary mt-2"
                                    >
                                        Download
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {!extra_details?.notes && !extra_details?.receipts?.length && (
                    <p>No additional details available for this transaction.</p>
                )}
            </ModalBody>
        </Modal>
    );
};

export default TransactionDetailsModal;
