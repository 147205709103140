import moment from "moment";

export const makeTimestamp = (time, dateTimestamp) => {
  // Convert the date timestamp to a proper date string (e.g., YYYY-MM-DD)
  const date = moment(dateTimestamp).format('YYYY-MM-DD');
  
  // Combine the date and time
  const dateTime = `${date} ${time}`;
  
  // Generate a Unix timestamp from the combined date and time
  const timestamp = moment(dateTime, 'YYYY-MM-DD HH:mm').unix();
  return timestamp;
};

// Function to format the timestamp back into time
export const formatTime = (timestamp) => {
  return moment.unix(timestamp).format('HH:mm');
};