import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import FolderItemModule from "../../components/Node/ItemModule";
import { ThreeBounce } from "better-react-spinkit";
import FaqService from "./FaqService";
import "../../components/Node/style.scss"

const FaqComponent = () => {
    const [newCategoryHeading, setNewCategoryHeading] = useState("");
    const [newCategoryDescription, setNewCategoryDescription] = useState("");
    const [action, setAction] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const [collapsedCategories, setCollapsedCategories] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                await FaqService.fetch();
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const handleCreateCategory = async (heading, description, action, parentId = "null") => {
        try {
            setIsCreating(true);
            await FaqService.create({ heading, description, parentId, isActive: true, action, isChildPresent: false });
            if (!!parentId) {
                await FaqService.updateParent(parentId);
            }
            setNewCategoryHeading("");
            setNewCategoryDescription("");
            setAction("")
        } catch (error) {
            console.error("Error creating category:", error);
        } finally {
            setIsCreating(false);
        }
    };

    const handleNewCategoryKeyDown = (event) => {
        if (event.key === "Enter" && newCategoryHeading.trim() !== "" && newCategoryDescription.trim() !== "") {
            handleCreateCategory(newCategoryHeading.trim(), newCategoryDescription.trim(), action?.trim());
        }
        else {
            // setIsCreating(false);
        }
    };

    const toggleCollapse = (id) => {
        setCollapsedCategories((prevCollapsed) => ({
            ...prevCollapsed,
            [id]: !prevCollapsed[id]
        }));
    };

    const renderCategories = (categories, level = 0) => {
        return categories
            .map(category => {
                try {
                    return (
                        <div key={category._id} style={{ paddingLeft: `${level * 20}px` }}>
                            <FolderItemModule
                                category={category}
                                isCollapsed={collapsedCategories[category._id]}
                                onToggleCollapse={() => toggleCollapse(category._id)}
                                onCreateSubCategory={handleCreateCategory}
                            />
                            {!collapsedCategories[category._id] && category.children && renderCategories(category.children, level + 1)}
                        </div>
                    );
                } catch (error) {
                    console.error("Error rendering category:", error);
                    return null;
                }
            });
    };

    const onBlur = () => {
        setNewCategoryHeading("");
        setNewCategoryDescription("");
        setAction("");
        setIsCreating(false);
    } 

    if (isLoading) {
        return (
            <div className="loader-container">
                <ThreeBounce color="#36d7b7" />
            </div>
        );
    }

    return (
        <div className="p-2 overflow-scroll">
            <div style={{ position: "absolute", }}>
                {isCreating ? (
                    <div className="d-flex">
                        <input
                            autoFocus
                            type="text"
                            placeholder="Heading"
                            value={newCategoryHeading}
                            onChange={(e) => setNewCategoryHeading(e.target.value)}
                            onKeyDown={handleNewCategoryKeyDown}
                            className="mt-2 category-creation-input"
                            // onBlur={onBlur}
                        />
                        <input
                            type="text"
                            placeholder="Description"
                            value={newCategoryDescription}
                            // onBlur={onBlur}
                            onChange={(e) => setNewCategoryDescription(e.target.value)}
                            onKeyDown={handleNewCategoryKeyDown}
                            className="mt-2 category-creation-input mx-2"
                        />
                        <input
                            type="text"
                            placeholder="Action"
                            value={action}
                            // onBlur={onBlur}
                            onChange={(e) => setAction(e.target.value)}
                            onKeyDown={handleNewCategoryKeyDown}
                            className="mt-2 category-creation-input"
                        />
                    </div>
                ) : (
                    <div className="create-category mt-2 d-flex align-items-center" onClick={() => {
                        setIsCreating(true)
                        console.log("object")
                    }}>
                        <FaPlus className="me-2" />
                        <span>Create Question Category</span>
                    </div>
                )}
            </div>
            <div className="mt-5" />
            {renderCategories(toJS(FaqService.records))}
        </div>
    );
};

export default observer(FaqComponent);