import moment from 'moment';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { DialogContext } from '../../store/context/DialogContext';
import { doPATCH, doPOST } from '../../util/httpUtil';
import { useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import { InputField, SelectField } from '../../components';
import "./style.css"

const BillingTab = ({ consultant_id, toggleTab }) => {
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [selectedBillingIds, setSelectedBillingIds] = useState([]);
    const { showMessage, showError, showConfirm, showAlert } = useContext(DialogContext);
    const [billings, setBillings] = useState([]);
    const [disbursalAmount, setDisbursalAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState('');
    const [receipts, setReceipts] = useState([]);

    const resetData = () => {
        setSelectedBillingIds([]);
        setDisbursalAmount('');
        setSelectedMonths([]);
        setAvailableMonths([]);
    }

    const handleFileChange = (event) => {
        setReceipts(event.target.files);
    };
    const handleRemoveFile = (index) => {
        setReceipts((prev) => prev.filter((_, i) => i !== index));
    };

    const uploadReceipts = async (record_id) => {
        try {
            const formData = new FormData();
            Array.from(receipts).forEach((receipt) => formData.append('pic', receipt));
            formData.append('_id', record_id);
            formData.append("module", "payout");
            const response = await doPOST('/v1/api/file/upload', formData);
            console.log(response);
            if (response.data?.data) {
                return await updateDisbursal(record_id, response.data?.data)
            }
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const generateLast12Months = () => {
        const months = [];
        for (let i = 1; i <= 12; i++) {
            const month = moment().subtract(i, 'months').format('YYYYMM');
            months.push(month);
        }
        return months;
    };

    const handleBillingSelection = (billingId) =>
        setSelectedBillingIds((prev) =>
            prev.includes(billingId) ? prev.filter((id) => id !== billingId) : [...prev, billingId]
        );


    const fetchAvailableMonths = (billings = []) => {
        const months = generateLast12Months();
        const existingBillingMonths = billings.map((billing) => billing.month?.toString());
        const filteredMonths = months.filter((month) => !existingBillingMonths.includes(month));
        setAvailableMonths(filteredMonths);
    };

    const fetchBillings = async (consultantId) => {
        try {
            const res = await doPOST('/v1/api/payout/billings', { consultant_id: consultantId });
            setBillings(res?.data?.data || []);
            fetchAvailableMonths(res?.data?.data || []);
        } catch (error) {
            console.error('Error fetching billings:', error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (consultant_id) {
            fetchAvailableMonths();
            fetchBillings(consultant_id);
        }
    }, [consultant_id]);

    const handleDisbursalSubmit = async () => {
        if (!selectedBillingIds.length) {
            showMessage("error", "Please select at least one billing month");
            return;
        }
        if (!disbursalAmount) {
            showMessage("error", "Please enter disbursal amount");
            return;
        }
        const data = {
            consultant_id: consultant_id,
            disbursal_amount: disbursalAmount,
            billing_ids: selectedBillingIds,
            extra_details: {
                notes
            }
        };

        try {
            setLoading(true);
            const response = await doPOST('/v1/api/payout/disbursal', data);
            if (response?.data?.data?._id && Array.from(receipts??[])?.length) {
                await uploadReceipts(response?.data?.data?._id);
            }
            showMessage("success", "Disbursal Created Successfully");
            toggleTab()
            resetData()
        } catch (error) {
            console.error('Error creating disbursal:', error);
            showMessage("error", "Failed to create disbursal");
        } finally {
            setLoading(false);
        }
    };

    const updateDisbursal = async (record_id, data) => {
        try {
            const response = await doPATCH(`/v1/api/payout/disbursal/update`, {
                extra_details: { receipts: data },
                _id: record_id
            });
            return response
        } catch (error) {
            console.error('Error creating disbursal:', error);
            return false;
        }
    };

    const createBillingForMonths = async () => {
        try {
            if (!selectedMonths.length) {
                showMessage("error", "Please select at least one billing month");
                return;
            }
            const res = await doPOST('/v1/api/payout/billings/calculate', {
                consultant_id: consultant_id,
                months: selectedMonths,
            });
            if (res?.data?.message === 'Billing calculated successfully') {
                showMessage("success", "Billing created successfully for selected months");
                fetchBillings(consultant_id);
            } else {
                alert('Failed to create billing for selected months');
            }
        } catch (error) {
            console.error('Error creating billing for months:', error);
            alert('Failed to create billing');
        }
    };

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Spinner color="primary" />
        </div>
    }

    return (
        <div className='d-flex flex-grow-1 flex-column p-2 p-lg-4 flex-1' >
            <div className='d-flex flex-grow-1 flex-column flex-1 '>
                {/* Select Months */}
                <div>
                    <h5>Select Months to Create Billing</h5>
                    <div className='d-flex flex-row align-items-center justify-content-between gap-2'>
                        <div className='flex-grow-1'>
                            <SelectField
                                data={availableMonths.map((month) => ({ value: month, label: moment(month, 'YYYYMM').format('MMMM YYYY') }))}
                                isMulti
                                placeholder="Select Billing month(s)"
                                value={selectedMonths}
                                onChange={(v) => Array.isArray(v) && setSelectedMonths(v?.map((m) => m.value))}
                            />
                        </div>
                        <Button className="btn btn-primary " onClick={createBillingForMonths}>
                            Create Billing for Selected Months
                        </Button>
                    </div>

                </div>

                {/* Available Billings */}
                <div className="mt-4">
                    <h5>Available Billings</h5>
                    {billings.length === 0 && !loading ? (
                        <div className="alert alert-info text-center">
                            No billings found. Please create billing by selecting the month(s) above.
                        </div>
                    ) : (
                        <table className="table table-bordered table-striped shadow">
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Month</th>
                                    <th>Total(AED)</th>
                                    <th>Paid(AED)</th>
                                    <th>Due(AED)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {billings.map((billing) => (
                                    <tr key={billing._id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedBillingIds.includes(billing._id)}
                                                onChange={() => handleBillingSelection(billing._id)}
                                            />
                                        </td>
                                        <td>{billing.month ? moment(billing.month, 'YYYYMM').format('MMMM YYYY') : '-'}</td>
                                        <td>{billing.total}</td>
                                        <td>{billing.paid}</td>
                                        <td>{billing.due}</td>
                                        <td>{billing.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>)}
                </div>

                {/* Total Due and Disbursal Amount */}
                <div className="mt-4 gap-2">
                    <label>Total Due for Selected Billings (AED) :  </label>
                    <strong>
                        {billings
                            .filter((billing) => selectedBillingIds.includes(billing._id))
                            .reduce((sum, billing) => sum + billing?.due ?? 0, 0)}
                    </strong>
                </div>
                <div className="mt-3 flex-row d-flex gap-2 align-items-center">
                    <label>Disbursal Amount:</label>
                    <InputField
                        className="col-sm-4"
                        required={true}
                        placeholder="Enter Disbursal Amount"
                        value={disbursalAmount}
                        onChange={(val) => setDisbursalAmount(val)}
                    />
                </div>

                <div className="mt-3 flex-row d-flex gap-2 align-items-center">
                    <label>Notes:</label>
                    <InputField
                        className="col-sm-8"
                        rows={100}
                        cols={100}
                        required={false}
                        placeholder="Add notes for this disbursal"
                        value={notes}
                        onChange={(val) => setNotes(val)}
                    />
                </div>

                <div className="mt-3 flex-row d-flex gap-2 align-items-center">
                    <label>Upload Receipt (Optional):</label>
                    <input type="file" accept=".jpeg,.jpg,.png," onChange={handleFileChange} multiple />
                </div>
                {/* Preview Section */}
                {/* <div className="preview-section d-flex flex-column gap-2">
                    {receipts?.map((file, index) => (
                        <div key={index} className="preview-item d-flex align-items-center gap-2">
                            {file.type.startsWith('image/') ? (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Preview"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px', border: '1px solid #ccc' }}
                                />
                            ) : (
                                <a
                                    href={URL.createObjectURL(file)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: 'blue' }}
                                >
                                    {file.name}
                                </a>
                            )}
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleRemoveFile(index)}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div> */}
            </div>

            {/* Create Button */}
            <Button className="btn btn-success mt-3 align-self-end" onClick={handleDisbursalSubmit}>
                Create Disbursal
            </Button>
        </div>
    )
}

export default BillingTab