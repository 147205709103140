import React from 'react'
import { InputField } from '../../components'

export default function TimeSelect(props) {
    return (
        <React.Fragment>
            <InputField
                className={props?.className}
                value={props.value}
                required={props?.required}
                label={props?.label}
                isDisabled={props?.isDisabled}
                onChange={(v) => props.onChange(v)}
                error={props?.value?.length === 0 ? "Please Choose Time" : ""}
                type="time"
                inline={props?.inline}
                borderNone={props?.borderNone}
                showErr={props.showErr}
                RightComponent={props?.RightComponent}
                onClickRight={props?.onClickRight}
            />
        </React.Fragment>
    )
}
