export const ENDPOINTS = {
    grid: (page, rowPerPage, filter) => {
      let url = `/v1/api/webinar/grid?&rowPerPage=` + rowPerPage + "&page=" + page

      return url
    },
    sessionGrid: (page, rowPerPage, filter, id) => {
      let url = `/v1/api/webinar/webinarSessions/${id}?&rowPerPage=` + rowPerPage + "&page=" + page
      return url
    },
    sessionCreate: (page, rowPerPage, filter, id) => {
      let url = `/v1/api/webinar-session/create`
      return url
    },
    get: (id) => `/v1/api/webinar/${id}/detail`,
    save: `/v1/api/webinar/create`,
    edit: `/v1/api/webinar/update`,
  };
  
  
  export const SELECTSTRUCTURE = {
    gender: [
      { label: "Mrs", value: 0 },
      { label: "Mr", value: 1 },
      { label: "Miss", value: 2 },
      { label: "Others", value: 3 },
    ],
  };
  export const STRUCTURE = [
    {
      label: "Client",
      description: "description...",
      filterLabel: "Client",
      filterName: "corp_id",
      type: "clientSelect",
    },
    {
      label: "Business Unit",
      description: "description...",
      filterLabel: "Business Unit",
      filterName: "bu_id",
      type: "entitySelect",
      dependency: {
        client: 'corp_id'
      }
    },
    {
      label: "Work Shift",
      description: "description...",
      filterLabel: "Work Shift",
      filterName: "dutyhrs_id",
      type: "workshiftSelect",
    },
    {
      label: "Campus",
      description: "description...",
      filterLabel: "Campus",
      filterName: "campus_id",
      type: "campusSelect",
    },
    {
      label: "Rank",
      description: "description...",
      filterLabel: "Rank",
      filterName: "rank",
      type: "rankSelect",
    },
    {
      label: "Designation",
      description: "description...",
      filterLabel: "Designation",
      filterName: "designation",
      type: "designationSelect",
    },
    {
      label: "Name",
      description: "description...",
      filterLabel: "Name",
      filterName: "name",
      type: "text",
    },
    {
      label: "Email",
      description: "description...",
      filterLabel: "Email",
      filterName: "email",
      type: "text",
    },
    {
      label: "From Date",
      description: "description...",
      filterLabel: "From Date",
      filterName: "joinFrom",
      type: "dateSelect",
      getTimestamp: true
    },
    {
      label: "Till Date",
      description: "description...",
      filterLabel: "Till Date",
      filterName: "joinTill",
      type: "dateSelect",
      getTimestamp: true
    },
    {
      label: "Time Zone",
      description: "description...",
      filterLabel: "Time Zone",
      filterName: "timezone",
      type: "timezoneSelect",
    },
    {
      label: "Country Code",
      description: "description...",
      filterLabel: "Country Code",
      filterName: "countryCode",
      type: "countrySelect",
      isd: true,
    },
    {
      label: "Phone",
      description: "description...",
      filterLabel: "Phone",
      filterName: "phone",
      type: "phone",
    },
    {
      label: "Country",
      description: "description...",
      filterLabel: "Country",
      filterName: "residence.country",
      type: "countrySelect",
    },
    {
      label: "City",
      description: "description...",
      filterLabel: "City",
      filterName: "residence.city",
      type: "citySelect",
      dependency: {
        country: 'residence.country',
      },
      onlyCountry: true,
    },
    {
      label: "Is On App",
      description: "description...",
      filterLabel: "Is On App",
      filterName: "onApp",
      type: "checkBox",
    },
    {
      label: "ETS Enabled",
      description: "description...",
      filterLabel: "ETS Enabled",
      filterName: "ets",
      type: "checkBox",
    },
    {
      label: "Spot Enabled",
      description: "description...",
      filterLabel: "Spot Enabled",
      filterName: "etsSpot",
      type: "checkBox",
    },
    {
      label: "Tracking Enabled",
      description: "description...",
      filterLabel: "Traking Enabled",
      filterName: "tracking",
      type: "checkBox",
    },
    {
      label: "Location coordinate not near from address",
      description: "description...",
      filterLabel: "Location coordinate not near from address",
      filterName: "notNear",
      type: "checkBox",
    },
    {
      label: "Location not populated properly",
      description: "description...",
      filterLabel: "Location not populated properly",
      filterName: "showLocAbsent",
      type: "checkBox",
    },
    {
      label: "Within Hotspot",
      description: "description...",
      filterLabel: "Within Hotspot",
      filterName: "withinHotspot",
      type: "checkBox",
    },
  ];
  