import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { CheckButton, InputField, SaveButton } from '../../components';
import { DialogContext } from '../../store/context/DialogContext';
import CategoryService from './CategoryService';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Checkbox from '../Consultant/Checkbox';
import ImageComponent from '../../components/Image/ImageComponent';
import { doPOST } from '../../util/httpUtil';
import ServiceCategorySelect from '../../components/SelectField/ServiceCategorySelect';

const EditCategory = () => {
    const { id } = useParams();
    const [data, setData] = useState({
        active: false,
        position: 99
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { showMessage, showError, showConfirm, showAlert } = useContext(DialogContext);
    let [backgroundImage, setBackgroundImage] = useState({
        file: null,
        reader: null
    })

    const onFileUpload = async (e) => {
        const selectedFile = e?.target?.files[0];
        setBackgroundImage(prev => ({
            ...prev,
            file: selectedFile,
        }));

        if (selectedFile) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setBackgroundImage(prev => ({
                    ...prev,
                    reader: fileReader.result,
                }));
            };
            fileReader.readAsDataURL(selectedFile);
        }
        if (!selectedFile) {
            return;
        }

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('_id', data?._id);
            formData.append('module', "category");
            formData.append('pic', selectedFile);
            const response = await doPOST('/v1/api/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.status === 200) {
                setData({ ...data, image: response?.data?.data?.[0] });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            setLoading(true);
            let response = {};
            if (id) response = await CategoryService.detail(id);
            setData(response);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getDetails();
        }
    }, [id]);

    const onSaveForm = async () => {
        if (!data?.name) {
            showError("Enter category name");
            return;
        }
        if (!data?.color_code) {
            showError("Enter color code for this category");
            return;
        }
        if (!data?.background_color_code) {
            showError("Enter background color for this category");
            return;
        }
        if ([null, undefined].includes(data?.position)) {
            showError("Enter position");
            return;
        }
        if (!data?.profession) {
            showError("Enter profession");
            return;
        }
        if (!data?.serviceCategory) {
            showError("Select a service type");
            return;
        }
        try {
            setLoading(true);
            let res = null;
            if (!id) {
                res = await CategoryService.create(data);
                if (res?.status === 200) {
                    showMessage("success", 'Category created', "Success");
                    navigate(`/category/edit/${res?.data?.data?._id}`);
                }
            }
            else {
                res = await CategoryService.edit(data);
                showMessage("success", 'Category updated', "Success");
                await getDetails();
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card>
            <CardBody className='m-2'>
                <Row className='d-flex align-items-end mb-3'>
                    <Col className='col-6 col-sm-4'>
                        <InputField
                            placeholder="Name"
                            label="Name"
                            value={data?.name}
                            onChange={(name) => setData({ ...data, name })}
                            showLabel
                            isRequired
                        />
                    </Col>
                    <Col className='col-6 col-sm-4'>
                        <Checkbox
                            label="Is Active"
                            id="active"
                            checked={data?.active}
                            onClick={(v) => setData({ ...data, active: v.target.checked })}
                        />
                    </Col>
                    <Col className='col-6 col-sm-4 d-flex align-items-end'>
                        <InputField
                            placeholder="Color Code"
                            label="Color Code"
                            value={data?.color_code}
                            onChange={(color_code) => setData({ ...data, color_code })}
                            style={{ flex: 1 }}
                            showLabel
                            isRequired
                        />
                        <div style={{
                            height: 30,
                            width: 30,
                            backgroundColor: data?.color_code || '#FFFFFF',
                            marginLeft: 10
                        }} />
                    </Col>
                </Row>

                <Row className='d-flex align-items-end mb-3'>
                    <Col className='col-6 col-sm-4 d-flex align-items-end'>
                        <InputField
                            placeholder="Background Color Code"
                            label="Background Color Code"
                            value={data?.background_color_code}
                            onChange={(background_color_code) => setData({ ...data, background_color_code })}
                            showLabel
                            isRequired
                            style={{ flex: 1 }}
                        />
                        <div style={{
                            height: 30,
                            width: 30,
                            backgroundColor: data?.background_color_code || '#FFFFFF',
                            marginLeft: 10
                        }} />
                    </Col>
                    <Col className='col-6 col-sm-4'>
                        <InputField
                            placeholder="Position"
                            label="Position"
                            value={data?.position}
                            onChange={(position) => setData({ ...data, position })}
                            type="number"
                            showLabel
                            isRequired
                        />
                    </Col>
                    <Col className='col-6 col-sm-4'>
                        <InputField
                            label="Profession"
                            placeholder="Profession"
                            value={data?.profession}
                            onChange={(profession) => setData({ ...data, profession })}
                            showLabel
                            isRequired
                        />
                    </Col>
                </Row>

                <Row className='col-6 col-sm-4 mb-4'>
                    <ServiceCategorySelect
                        label="Service Type"
                        placeholder="Service Type"
                        onChange={(v) => setData({ ...data, serviceCategory: v?.value })}
                        value={data?.serviceCategory}
                        isRequired={true}
                    />
                </Row>

                {data?._id ? <div className="position-relative" style={{ display: "flex", alignItems: "center", }}>
                    <div>
                        <div
                            style={{
                                width: 100,
                                height: 100,
                                borderRadius: "50%",
                                overflow: "hidden",
                            }}
                        >
                            {backgroundImage?.file ? <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={URL.createObjectURL(backgroundImage?.file)} /> :
                                <ImageComponent type="default" source={data?.image} style={{ width: "100%", height: "100%", objectFit: "cover" }} />}
                        </div>
                    </div>
                    <div className="px-4">
                        <div className="image-upload-consultant profile-edit cursor-pointer">
                            <label htmlFor="backgroung-image">Upload new image</label>
                            <input hidden id='backgroung-image' type="file" onChange={onFileUpload} />
                        </div>
                    </div>
                </div> : null}
            </CardBody>
            <div className='d-flex justify-content-end'>
                {/* {id ? <SaveButton
                    loading={loading}
                    onClick={() => onDelete(id, true)}
                    label={"Delete"}
                    disabled={loading}
                    className='col-1'
                /> : null} */}
                <SaveButton
                    loading={loading}
                    onClick={onSaveForm}
                    label={id ? "Update" : "Add"}
                    disabled={loading}
                    className='col-1 ms-4'
                />
            </div>
        </Card>
    )
}

export default observer(EditCategory);