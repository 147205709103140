import { makeAutoObservable } from "mobx";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/httpUtil";
import { ENDPOINTS } from "./FaqConstant";

class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 40;
    filterOption = '';

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function (filter) {
        if (filter !== this.filterOption) {
            this.page = 1
            this.rows = 40
        }
        this.filterOption = filter;
        const response = await doGET(ENDPOINTS.grid(`?rows=-1`));
        const arr = this.buildHierarchy(response?.data?.data?.rows);
        if (response.status === 200) {
            this.records = arr;
            this.totalRecords = response.data?.data?.total
        }
        return response?.data
    };

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        await this.fetch(this.filterOption);
    };

    create = async function (data) {
        const response = await doPOST(ENDPOINTS.create, data);
        this.fetch();
        return response;
    };

    updateParent = async function (id) {
        const data = await this.detail(id);
        const res = await this.edit({ ...data, isChildPresent: true })
    }

    edit = async function (data) {
        const response = await doPUT(ENDPOINTS.update, data);
        this.fetch();
        return response;
    };

    delete = async function (id) {
        const response = await doDELETE(ENDPOINTS.delete((id)));
        this.fetch();
        return response;
    };

    detail = async function (id) {
        const response = await doGET(ENDPOINTS.detail((id)));
        return response?.data?.data ?? {};
    };


    buildHierarchy = (categories) => {
        let hierarchy = [];
        let lookup = {};

        categories?.forEach(category => {
            lookup[category._id] = { ...category, children: [] };
        });

        categories?.forEach(category => {
            if (category?.parentId && lookup[category?.parentId]) {
                lookup[category.parentId]?.children.push(lookup[category?._id]);
            } else {
                hierarchy.push(lookup[category?._id]);
            }
        });
        return hierarchy;
    };
}

const FaqService = new Service();
export default FaqService;